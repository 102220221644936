import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import ExtensionIcon from '@material-ui/icons/Extension';
import PaletteIcon from '@material-ui/icons/Palette';
import themes from '../../themes';
export const configuredTheme = themes[0].theme;
export const configuredLayout = {
  currentLayout: 'classic',
  notificationsOpen: false
};

const iconStyle = {
  fontSize: 16
};

export const EndUserItems = [
  {
    title: 'DASHBOARD',
    href: '/user-dashboard',
    icon: <HomeIcon style={iconStyle} />,
  },
  {
    title: 'FEED',
    href: '/social-feed',
    icon: <i className="far fa-search" />,
  },
  {
    title: 'SEARCH',
    href: '/search',
    icon: <i className="far fa-search" />,
  }
];
