import React, {Component} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app.component';
import { Provider } from 'react-redux';
import store  from './client/store';
import WebFont from 'webfontloader';
import ReduxToastr from "react-redux-toastr";

import './index.css';
WebFont.load({
    google: {
        families: ['Barlow:300,400,400i,500,600,700']
    }
});

class AppJS extends Component {
    render() {
        return (<Provider store={store}>
            <Router >
                <App />
            </Router>
            <ReduxToastr
                timeOut={5000}
                newestOnTop={true}
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
            />
        </Provider>);
    }
}
export default AppJS;