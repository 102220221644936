import {GET_SPONSOR_COUNT} from '../../actiontypes';

const initial_state = {
    unreadSpomsg: false
};
const UnreadSpoReducer = (state = initial_state, action) => {
    switch (action.type) {
        case GET_SPONSOR_COUNT:
            return { ...state, unreadSpomsg : action.payload }
        
        default:
            return state;
    }
}
export default UnreadSpoReducer;