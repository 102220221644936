import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import MenuSidenavItem from './Navigation-Item';

import { menuItems } from '../config';
import {CommunitymenuItems} from "../client/routes/communityConfig";
import {ProvidermenuItems} from "../client/routes/ProviderConfig";
import {VolunteermenuItems} from "../client/routes/volunteerConfig";
import {EndUserItems} from "../client/routes/EndUserConfig";
import {SubProvidermenuItems} from "../client/routes/SubProviderConfig";

import {LoginFetchReducer} from "../client/actions/users";
import {SponsorCountReducer} from "../client/actions/sponsors";
import {UnreadmsgFetchReducer} from "../client/actions/unreadMessages";
import {UnreadConnectionFetchReducer} from "../client/actions/connections";

let classes = {};
class MenuSidenav extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchor: null, open: null,menuItems : [], unreadmsg: false, userType: false };
  }

  componentDidMount() {
    if (this.props.stateLoginValue && this.props.stateLoginValue.user_type) {
      this.setState({ userType: this.props.stateLoginValue.user_type});
    }
    classes = {
      list: {
        paddingTop: 0,
        paddingBottom: 0
      },
      listItem: {
        textAlign: 'left'
      },
      drawerHeader: '',
      nested: {
        paddingLeft: '10px',
        textAlign: 'left'
      },
      contentWrapper: {
        backgroundColor: '#ccc',
        position: 'relative',
        overflow: 'hidden',
        flex: '1 1 100%',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box'
      },
      content: {
        width: '100%',
        minWidth: '100%',
        height: '100%',
        minHeight: '100%',
        margin: '0',
        overflowY: 'auto',
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
          width: '0'
        }
      },
      toolbarContainer: {
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        padding: '0 16px',
        position: 'relative'
      }
    };

    if (!this.props.stateLoginValue.user_type) {
      this.setState({menuItems: menuItems});
    }
    if (this.props.stateLoginValue.user_type === 0) {
      this.setState({menuItems: CommunitymenuItems}) ;
    }
    if (this.props.stateLoginValue.user_type === 1) {
      this.setState({menuItems: ProvidermenuItems});
    }
    if (this.props.stateLoginValue.user_type === 2) {
      this.setState({menuItems:VolunteermenuItems});
    }
    if (this.props.stateLoginValue.user_type === 3) {
      this.setState({menuItems:EndUserItems});
    }
    if (this.props.stateLoginValue.user_type === 4) {
      this.setState({menuItems:SubProvidermenuItems});
    }
    if (this.props.stateLoginValue.user_type === "") {
      this.setState({menuItems: menuItems});
    }
  }

  constructNavItems = (itemsArray, classes) => {
    const arr = [];
    itemsArray.forEach((el) => {
      arr.push((
        <MenuSidenavItem
          title={el.title}
          href={el.href}
          key={el.title}
          icon={el.icon}
          type={el.type}
        >
          {el.children && this.constructNavItems(el.children, classes)}
        </MenuSidenavItem>));
    });
    return <List className={classes.list}>{arr}</List>;
  }

  render() {
    return (
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          {this.constructNavItems(this.state.menuItems, classes)}
        </div>
      </div>
    );
  }
}

MenuSidenav.propTypes = {
  classes: PropTypes.shape({}).isRequired
};

const mapStateToProps = (state) => {
  const { stateLoginValue } = state.LoginReducer;
  return {
    stateLoginValue
  }
};

export default connect(mapStateToProps, { LoginFetchReducer,SponsorCountReducer, UnreadmsgFetchReducer, UnreadConnectionFetchReducer })(MenuSidenav);
