import React from 'react';
// import TextFormatIcon from '@material-ui/icons/TextFormat';
// import PersonIcon from '@material-ui/icons/Person';
import FaceIcon from '@material-ui/icons/Face';
import themes from '../../themes';

export const configuredTheme = themes[0].theme;
export const configuredLayout = {
  currentLayout: 'classic',
  notificationsOpen: false
};

const iconStyle = {
  fontSize: 16
};

export const SubProvidermenuItems = [
  // {
  //   title: 'HOME',
  //   href: '/',
  //   icon: <HomeIcon style={iconStyle} />,
  // },
  {
    title: 'DASHBOARD',
    href: '/sub-provider-listing',
    icon: <FaceIcon style={iconStyle} />
  },
  {
    title: 'MY SECONDARY PROVIDER',
    href: '/secondary-provider-manage',
    icon: <FaceIcon style={iconStyle} />
  },
  {
    title: 'MY CONNECTIONS',
    href: '/subprovider-connections',
    icon: <FaceIcon style={iconStyle} />
  },
  {
    title: 'MY SPONSORED COMMUNITIES',
    href: '/sponsor-view',
    icon: <FaceIcon style={iconStyle} />
  },
  {
    title: 'MY MESSAGES',
    href: '/messages',
    icon: <FaceIcon style={iconStyle} />
  },
  // {
  //   title: 'SECONDARY PROVIDER',
  //   icon: <PaletteIcon style={iconStyle} />,
  //   children: [
  //     // {
  //     //   title: 'Add Secondary Provider',
  //     //   href: '/create-option',
  //     //   icon: <FaceIcon style={iconStyle} />
  //     // },
     
  //   ]
  // },
  // {
  //   title: 'ABOUT',
  //   icon: <DesktopWindowsIcon style={iconStyle} />,
  //   href: '/about',
  // },
  // {
  //   title: 'MY PROFILE',
  //   icon: <PersonIcon style={iconStyle} />,
  //   href: '/sub-provider-details'
  // }, 
  // {
  //   title: 'MY SETTING',
  //   href: '/setting',
  //   icon: <TextFormatIcon style={iconStyle} />,
  //   },
];
