import {GET_UNREAD_CONNECTION} from '../../actiontypes';

const initial_state = {
    unreadConnmsg: false
};
const UnreadConnReducer = (state = initial_state, action) => {
    switch (action.type) {
        case GET_UNREAD_CONNECTION:
            return { ...state, unreadConnmsg : action.payload }
        
        default:
            return state;
    }
}
export default UnreadConnReducer;