import {GET_SEARCH_TYPE, GET_SEARCH_TYPE_FAIL} from '../../actiontypes';

const initial_state = {
    stateSearchData:[]
};
const SearchReducer = (state = initial_state, action) => {
    switch (action.type) {
        case GET_SEARCH_TYPE:
            return { ...state,  stateSearchData:action.payload }
        case GET_SEARCH_TYPE_FAIL:
            return { ...state }    
        default:
            return state;
    }
}
export default SearchReducer;