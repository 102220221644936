import {GET_COMMUNITY_TYPE, GET_COMMUNITY_TYPE_FAIL} from '../../actiontypes';

const initial_state = {
    stateCommunityType:[],
    myCommunityInfo:[],
};
const CommunityReducer = (state = initial_state, action) => {
    switch (action.type) {
        case GET_COMMUNITY_TYPE:
            return { ...state, stateCommunityType:action.payload, myCommunityInfo:action.payload }
        case GET_COMMUNITY_TYPE_FAIL:
            return { ...state }    
        default:
            return state;
    }
}
export default CommunityReducer;