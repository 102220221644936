import {combineReducers} from 'redux';
import LoginReducer from './reducers/LoginReducers'
import themeReducer from './reducers/theme.reducer';
import layoutReducer from './reducers/layout.reducer';
import CommunityReducer from './reducers/community.reducer'
import SearchReducer from './reducers/search.reducer'
import UnreadmsgReducer from './reducers/unread.reducer';
import UnreadConnReducer from './reducers/unreadConn.reducer';
import SponsorReducer from './reducers/sponsor.reducer';
import UnreadSpoReducer from './reducers/sponsorcount.reducer'


import pagesReducer from './reducers/pagesReducer';
import { reducer as toastrReducer } from 'react-redux-toastr'
import UnreadAlertsReducer from "./reducers/unreadAlerts.reducer";
import FamilyMemberReducer from "./reducers/familyMembers.reducer";
import SelectedFamilyMemberReducer from "./reducers/selectedFamilyMemberReducer";

const todoApp = combineReducers({
  theme: themeReducer,
  layout: layoutReducer,
  LoginReducer : LoginReducer,
  CommunityReducer: CommunityReducer,
  SearchReducer: SearchReducer,
  UnreadmsgReducer : UnreadmsgReducer,
  UnreadAlertsReducer : UnreadAlertsReducer,
  UnreadConnReducer : UnreadConnReducer,
  SponsorReducer: SponsorReducer,
  UnreadSpoReducer: UnreadSpoReducer,

  toastr: toastrReducer,
  pages: pagesReducer,
  familyMembers: FamilyMemberReducer,
  selectedFamilyMember: SelectedFamilyMemberReducer
});

export default (todoApp);