
import {GET_LOGIN_REQUEST, GET_LOGIN_SUCCESS, GET_LOGIN_ERROR } from '../../actiontypes';
import axios from "axios";
import { sitedata } from '../../sitedata'


export const LoginFetchReducer = (email, password) => {
    return (dispatch) => {
        dispatch({ type: GET_LOGIN_REQUEST });
        axios.post(sitedata.api +'/user/login',{ email, password })
        .then((response) =>{

            console.log("response.data", response.data);
            let tmp;
            if(response.data.hassuccessed === false) {
                let tmp = {
                    token : 450,
                    user_type: '',
                    message: response.data.message
                }
                dispatch({ type: GET_LOGIN_SUCCESS, payload :tmp});
            }
            if(response.data.hassuccessed === true){
                tmp = {
                    token:response.data.token,
                    user_type: response.data.data.user_type,
                    profileImage: response.data.data.user_img,
                    userTitle: response.data.data.title,
                    userId: response.data.data.userId,
                    user_id: response.data.data.user_id,
                    email: response.data.data.email,
                    usersName: response.data.data.f_name + " " + response.data.data.l_name,
                    communityCount: response.data.communityCount,
                    fromRegisterPage: response.data.data.fromRegisterPage,

                };
                dispatch({ type: GET_LOGIN_SUCCESS, payload :tmp});
            }
        }).catch((error) => {
            let tmp = 'error';
            dispatch({ type: GET_LOGIN_ERROR , payload :tmp});
        });
    }
};
