import {GET_UNREAD_ALERTS, GET_UNREAD_FAIL} from '../../actiontypes';

const initial_state = {
    unreadAlerts: false
};
const UnreadAlertsReducer = (state = initial_state, action) => {
    switch (action.type) {
        case GET_UNREAD_ALERTS:
            return { ...state, unreadAlerts : action.payload }

        case GET_UNREAD_FAIL:
            return { ...state }

        default:
            return state;
    }
}
export default UnreadAlertsReducer;